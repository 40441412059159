import httpStatusCodes from '@/helpers/httpStatusCodes';
import auth from './modules/auth';
import token from './modules/token';
import companyId from './modules/companyId';

const APP_URL_ORIGIN = self.location.origin;
export const API_CACHE_NAME = 'nitrogen-api-cache';

export function offlineResponse() {
	return new Response(null, { status: 503, statusText: 'Offline' });
}

export function isOfflineResponse(response) {
	return response instanceof Response && response.status === 503 && response.statusText === 'Offline';
}

export function isAborted(error) {
	return isAbortedError(error) || isAbortedResponse(error);
}

function isAbortedError(error) {
	return error instanceof DOMException && (error.name === 'AbortError' || error.code === error.ABORT_ERR);
}

function isAbortedResponse(response) {
	return response instanceof Response && response.status === 0;
}

export function notFoundResponse() {
	return new Response(null, { status: 404, statusText: 'Not Found' });
}

/**
 *
 * @param {Object|String|null} data - response data (defaults to null)
 * @param {Number} status - response status (defaults to 200)
 * @returns {Response} new response
 */
export function idbResponse(status = 200, data = null) {
	if (typeof data === 'object' && data !== null) {
		data = JSON.stringify(data);
	}
	const statusText = (httpStatusCodes[status] ?? '') + ' from IDB';
	return new Response(data, { status, statusText });
}

export function isIdbResponse(response) {
	return response instanceof Response && response.statusText && response.statusText.endsWith('from IDB');
}

export function setPagination(limit, start) {
	const query = {
		limit: 100,
		start: 0,
	};
	if (typeof limit === 'number' && limit >= 1 && limit <= 100) {
		query.limit = limit;
	}
	if (typeof start === 'number' && start >= 0) {
		query.start = start;
	}
	return query;
}

/**
 *
 * @param {string|URL} url
 * @param {*} init
 * @returns {Promise<Response>} Fetch API response
 */
export async function fetchWrap(url, init) {
	const _token = token.get();
	if (_token) {
		url = url instanceof URL ? url : new URL(url, APP_URL_ORIGIN);
		url.searchParams.set('token', _token);
	}
	url = addCompanyIdToUrl(url);
	const response = await fetch(url, init);
	auth.callCallbacks(response);
	return response;
}

export function addCompanyIdToUrl(url) {
	const _companyId = companyId.get();
	if (_companyId) {
		url = url instanceof URL ? url : new URL(url, APP_URL_ORIGIN);
		url.searchParams.set('companyId', _companyId);
	}
	return url;
}

/**
 *
 * @param {*} requestUrl
 * @param {Function} callback
 * @param {*} init
 * @param {int} limit
 * @returns
 */
export async function fetchAllPages(requestUrl, callback, init, limit = 100) {
	if (typeof callback !== 'function') { throw new TypeError('Callback function required.'); }

	limit ??= 100;
	let start = 0;
	let hasNextPage = true;
	let response = null;
	do {
		let url = new URL(requestUrl, APP_URL_ORIGIN);
		url.searchParams.set('limit', limit);
		url.searchParams.set('start', start);
		response = await fetchWrap(url, init);
		if (response.ok) {
			const responseData = await response.json();
			if (Array.isArray(responseData.data)) {
				for (let i = 0; i < responseData.data.length; i++) {
					const returnValue = callback.call(null, responseData.data[i], start + i);
					if (returnValue instanceof Promise) {
						await returnValue;
					}
				}
			}
			hasNextPage = responseData.hasNextPage;
			start += limit;
		} else {
			break;
		}
	} while (hasNextPage);
	return response;
}
